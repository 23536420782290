import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '4gX6uHTtVq4s7ulMJjmdmP',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - ITALY',
        slides: [
          {
            id: '227dUSooZanw0AOgYUTVmR',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4NmUqNL01cTIBmngJQsoYY/8edd31c10e1caee545b00949ba741ea3/GENERIC_HPBANNERS_iPhone13_Desktop_IT.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/73HvlAmFG0mSskN3Uj7Yhi/52349fe51c4db34b6cd27e37d0cd4b20/GENERIC_HPBANNERS_iPhone13_Mobile_IT.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'IT - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.it/it-it/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '6wKzm053zH14I0YBJbKZPr',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7ghliaI0hJ0AaeY1dihP0g/1694bf7a06c081b9365687b7e258446b/Good_Deals_HP_Desktop_IT.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/20dfYVOO5CWHYRW2O47xXg/5daca2a4d338d40b250e2eb1fde41344/Good_Deals_HP_Mobile_IT.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'IT - Good Deals ',
            },
            link: {
              href: 'https://www.backmarket.it/it-it/e/good-deals',
            },
          },
          {
            id: 'lEmrd4Jm2VQWywEtUkdMH',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/51K8vxqk1hXmfdPwirvL41/55c0b07c15f33edf936870ea2e77ce46/desktop-IT__1_.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/8FC2YHhLJxAyQr8p9RcXx/6b7c4e9f0014620ba55c0ee702e550cf/mobile-IT__1_.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'IT Backup - 2024',
            },
            link: {
              href: 'https://www.backmarket.it/it-it/e/backup',
            },
          },
          {
            id: '54473cR47YXIWiaEXbCkI3',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1VWlUEQMRgYwUwk7W3yh2h/97d863f3b2de77dbb478f4926dfe49c2/AboutUs_HP_Desktop_IT.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3eOFL1z2t9CnCCNvJWebt1/6e4cbbcc65547bd7d6ec5b36c0d1a2bf/Copy_of_VISION_Mobile_IT.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'IT - Vision',
            },
            link: {
              href: 'https://www.backmarket.it/it-it/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '21GiTOKuHAECzafAd7xs2t',
      type: 'Categories',
      props: {
        title: 'BestSeller',
        subtitle: 'I prodotti più ricercati sul nostro sito.',
        categories: [
          {
            id: '44sX4voDhNlaTsJ6kClNE0',
            title: 'Smartphone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1ORhFpfN5jvxd149qrpAl5/045ece85f81778872283d835a6ba6dab/None_ca03a5ff-7ab6-4869-b6c2-976b0c08e32a-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12 KN',
            },
            tagline: 'Ancora più smart di quelli nuovi.',
            link: {
              href: 'https://www.backmarket.it/it-it/l/smartphone/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: '3VKrcyz5zZa889l2tTr82m',
            title: 'iPad',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4mw1VnREHuLh2fhAkwD1z4/46357f541ab2864ac12d6d88b5221d05/None_dae26af1-c478-409d-ae33-adc__1_.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro 2022',
            },
            tagline:
              "Fino al 40% di sconto. Così ci scappa anche l'abbonamento a Netflix.",
            link: {
              href: 'https://www.backmarket.it/it-it/l/ipad/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
            },
          },
          {
            id: '2BvM8EWMGuBnJvmS3UmDHN',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3YMiDmVjmnXTkri7J7qWW0/9e3621344b8f567a0db85d9a0711c3e0/Apple_Watch_Series_8_-_Black.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7dxmoX1a6jzPgz7F2xtWzW/896867734cb01e708630395eb1fe8368/Apple_Watch_Series_8_-_Black.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'Apple Watch Series 8 - Black',
            },
            tagline: 'È ora di dire addio al tuo vecchio Casio.',
            link: {
              href: 'https://www.backmarket.it/it-it/l/apple-watch/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
          {
            id: '5KDpAMs9WgZRSQEnRgPODD',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/9MX5iyCngiIXMgzDS29OV/121f29a184b8cb7d8add96ee9acb2169/MacBook_Air__2022__M2.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/HGe7hQv5gWmrrG5kj0o5s/13c811245a0c9b1f10c4f0758fde5df6/MacBook_Air__2022__M2.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'MacBook Air M2',
            },
            tagline:
              "MacBook Air e MacBook Pro a prezzi più che convenienti. Se l'avesse saputo Steve Jobs...",
            link: {
              href: '',
            },
          },
          {
            id: '5fr8A1UAPSpWDHlng887js',
            title: 'PC portatili',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5wRFyaf2nEBVlUHR5SZuq2/9b3c3195c4e6793bd2909dc59e76bd8e/Chromebooks___Laptops.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop Windows',
            },
            tagline: 'Laptop, notebook e compagnia bella.',
            link: {
              href: 'https://www.backmarket.it/it-it/l/notebook/630dab14-5051-49b9-bc7b-bb20876d4850',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '5BUf2H1jdjKikMqn08IdJm',
      type: 'Categories',
      props: {
        title: 'Altre categorie',
        subtitle: 'Niente si crea, niente si distrugge, tutto si ricondiziona.',
        categories: [
          {
            id: '605QrlorNzdwLbww50kuSA',
            title: 'Area Apple',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5VSagRpzOyIad06fpq9RSp/093b01291781d00c5a4c33f876a4ad13/imacUS.jpeg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iMac',
            },
            tagline: "Molto meglio dell'Apple Store.",
            link: {
              href: 'https://www.backmarket.it/it-it/l/apple/befd8e84-d318-48eb-88ef-362979a53183',
            },
          },
          {
            id: '1BK1hJU7RoLGJSWgDolMz2',
            title: 'Audio',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/d96a0ef601217c1f1e112fc36ef0977e/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Airpods Pro',
            },
            tagline: 'La musica addolcisce i prezzi.',
            link: {
              href: 'https://www.backmarket.it/it-it/l/sono-cuffie-casse/6a6b4d5a-c50d-4a61-9a7a-3c3b1ce00e50',
            },
          },
          {
            id: '2OSMTY65kGqEoIbbEKFgwZ',
            title: 'Foto e video',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/51pMifL5BxMUDHSVwyAeBE/78f986bfa45c3306c30c05a63eea7f80/129798_a2cc9fc1-07fb-47cf-8613-ff82a354a3f8-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5T5ucA4BO8D0FC6BUnYB8a/a31e22c265b75c133f715fc381d48bfd/esfNikon.jpeg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'ES- BF Nikon',
            },
            tagline:
              'Tutto il necessario per immortalare i momenti delle tue giornate.',
            link: {
              href: 'https://www.backmarket.it/it-it/l/fotocamera-videocamera-ricondizionata/07f6ddc5-ec6d-47d1-9ae8-4fe5e6e53c19',
            },
          },
          {
            id: '15xQ1UnqB2hmumaL7YCq0w',
            title: 'Elettrodomestici',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6eCU0vSokY26aItRpbc46L/8729970882b416d69e6f1cff9e2a9f40/DOLCE_GUSTO-removebg-preview.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Dolce gusto category block',
            },
            tagline:
              'Frullatori, mixer, tostapane, robot da cucina, Bimby e chi più ne ha più ne metta!',
            link: {
              href: 'https://www.backmarket.it/it-it/l/piccolo-elettrodomestico/2cbc910d-763c-415d-b86d-622d28db5947',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '5XpbKkEmAlGtnsILODRPjD',
      type: 'PressCarousel',
      props: {
        technicalName: 'IT - Press Carousel',
        subtitle: 'E anche molto bene!',
        title: 'Parlano di noi...',
        articles: [
          {
            id: '139WQAaFfTBlYAnGw8964D',
            text: 'Back Market, piattaforma digitale che vende solo device ricondizionati',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/d5B21MwHGpIiczvpt5UQE/5a5a47f46cfc77a11d305881392c463e/download.png',
              width: 600,
              height: 55,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/d5B21MwHGpIiczvpt5UQE/5a5a47f46cfc77a11d305881392c463e/download.png',
              widthMobile: 600,
              heightMobile: 55,
              alt: 'corriere della sera logo',
            },
            link: {
              href: 'https://www.corriere.it/economia/consumi/22_maggio_14/aggiustare-non-buttare-si-afferma-repair-economy-piu-green-conveniente-78004416-cfa1-11ec-99b8-03572084bce6.shtml',
            },
            publicationDate: '05/2022',
          },
          {
            id: '3HaiUYAyNaO3X8ajT3jJne',
            text: "Back Market, che ha raggiunto una valorizzazione pari a 5,1 miliardi di euro, punta all'Italia",
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2oTu18YQ8ht2Bc430jNGsw/554a146d439d1e315301af5eef4e7cf2/Logo_Startupbusiness.png',
              width: 500,
              height: 192,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2oTu18YQ8ht2Bc430jNGsw/554a146d439d1e315301af5eef4e7cf2/Logo_Startupbusiness.png',
              widthMobile: 500,
              heightMobile: 192,
              alt: 'Startup Business Logo IT',
            },
            link: {
              href: 'https://www.startupbusiness.it/back-market-la-scaleup-francese-punta-allitalia/118801/',
            },
            publicationDate: '05/2022',
          },
          {
            id: '7LZ2SNkICVnAcPccTwBW7e',
            text: 'Prodotti elettronici ricondizionati: funding round da 276 milioni di euro per Back Market',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4OhbaXG8jKexijtRFi9bFE/4916c2b02f93c0ef51eeda60a3704524/quifinanza-300x82.png',
              width: 300,
              height: 82,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4OhbaXG8jKexijtRFi9bFE/4916c2b02f93c0ef51eeda60a3704524/quifinanza-300x82.png',
              widthMobile: 300,
              heightMobile: 82,
              alt: 'QuiFinanza',
            },
            link: {
              href: 'https://quifinanza.it/economia/back-market-funding-round-276-milioni-euro/491559/',
            },
            publicationDate: '05/2021',
          },
          {
            id: '6JXwR9AKF68uAEblgA5Ilo',
            text: 'Un marketplace per cambiare la cultura dei consumi high-tech',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/d5B21MwHGpIiczvpt5UQE/5a5a47f46cfc77a11d305881392c463e/download.png',
              width: 600,
              height: 55,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/d5B21MwHGpIiczvpt5UQE/5a5a47f46cfc77a11d305881392c463e/download.png',
              widthMobile: 600,
              heightMobile: 55,
              alt: 'corriere della sera logo',
            },
            link: {
              href: 'https://corriereinnovazione.corriere.it/2016/08/31/telefonini-tablet-revisionati-cosi-si-risparmia-non-si-inquina-6a368cda-6f82-11e6-856e-2cdca5568f05.shtml',
            },
            publicationDate: '08/2016',
          },
          {
            id: '66ItKqAswj9RaEAGB2oqh3',
            text: 'Back Market, il supermercato del tech ricondizionato',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/xx1hSDtDV5i4erQRr8Mfy/d0951fd07ab1fbc396496627d6e3a1f0/ansa.png',
              width: 382,
              height: 132,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/xx1hSDtDV5i4erQRr8Mfy/d0951fd07ab1fbc396496627d6e3a1f0/ansa.png',
              widthMobile: 382,
              heightMobile: 132,
              alt: 'ANSA.it',
            },
            link: {
              href: 'https://www.ansa.it/sito/notizie/tecnologia/hitech/2016/07/27/su-back-market-il-tech-ricondizionato_9f74f796-28f3-444d-bbef-05ccf89494e8.html',
            },
            publicationDate: '07/2016',
          },
          {
            id: 'dDBXon5Bkdto2GaSBqUc4',
            text: 'Una valida alternativa alla grande distribuzione di elettronica',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/01KIgxvOKbcNfBshAccyod/8a9b10b928eb6e55bfa9c7e5334620f7/GreenStyle-Logo-1.png',
              width: 400,
              height: 50,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/01KIgxvOKbcNfBshAccyod/8a9b10b928eb6e55bfa9c7e5334620f7/GreenStyle-Logo-1.png',
              widthMobile: 400,
              heightMobile: 50,
              alt: 'Greenstyle',
            },
            link: {
              href: 'https://www.greenstyle.it/back-market-shop-dei-prodotti-ricondizionati-debutta-italia-201138.html',
            },
            publicationDate: '07/2016',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '7JDCd7fK7zEf22pGgjmsZ3',
      type: 'Faq',
      props: {
        title: "Cos'altro?",
        subtitle: 'Le quattro domande che ci fanno più spesso',
        questions: [
          {
            id: '7oZdioXZu5lxsoShab5gqj',
            title:
              'Come faccio a sapere che il mio dispositivo non smetterà di funzionare tra un mese?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Ci impegniamo al 110% per garantire che il prodotto offerto rispetti le specifiche previste: dal processo di screening degli annunci di tutti i venditori agli "ordini misteriosi" effettuati dal personale tecnico di Back Market (con nomi in codice, tra l\'altro).',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '4qFgjhtAub2S3tTZ6PFCp8',
            title:
              "Che differenza c'è tra un dispositivo ricondizionato e uno nuovo?",
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "All'apparenza uno smartphone ricondizionato sembra e funziona come uno nuovo. Ma è quello che c'è dentro che fa la differenza. La tecnologia ricondizionata (smartphone, tablet, PC portatili e computer) ha inoltre",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'text',
                      value: ' ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.it/it-it/c/iphone/refurbished-smartphones',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value:
                            'un impatto ambientale inferiore rispetto a quello dei dispositivi nuovi',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6xhX1m3kap7PxKiwlzs1ZI',
            title: "Cos'altro vendete?",
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Puoi aiutare il Pianeta in vari modi. Quando si parla di tecnologia, noi possiamo offrirti molto di più di un semplice smartphone. Dalle console di gioco ai ferri arricciacapelli o persino ai baby monitor (sì, stiamo parlando proprio a te, neogenitore) e alle macchine per il caffè espresso, con tutta probabilità abbiamo quello che stai cercando.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '2wKoVLpfmLbinsazlHs6Wj',
            title:
              'Il T-800 in Terminator 2 - Il giorno del giudizio (1991) era tecnicamente "ricondizionato"?',
            text: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Avremmo sicuramente bisogno di sottoporre Skynet al nostro processo di certificazione prima di dare una risposta definitiva a questa domanda, ma abbiamo la sensazione che sì, molto probabilmente è "tornato" anche lui.',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: BLOCKS.PARAGRAPH,
                },
              ],
              nodeType: BLOCKS.DOCUMENT,
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.it/it-it/help',
          },
          label: 'Trova le risposte',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Home',
  meta: {
    title: 'Il tuo (Super) Marketplace di prodotti ricondizionati',
    description:
      'Acquista i migliori Smartphone, iPhone, iPad, MacBook e Laptop ricondizionati con sconti fino al 70% rispetto al nuovo! Consegna Gratis - Garanzia 12 mesi - 30 giorni di Prova Gratis.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/bac69416f2fa3f8278702a8735ab3f87/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Il tuo (Super) Marketplace di prodotti ricondizionati',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
